<template>
  <div class="bigbox">
     <van-nav-bar title="隐私权政策" left-arrow @click-left="$router.back()"></van-nav-bar>
     <div class="box">
        <div class="title">
            <h2>隐私权政策</h2>
        </div>
        <div style="text-align: center;">
        </div>
        <div>
            <h3>法律声明：</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="app_name"></span>运营主体（以下简称“我们、米花棠、米花棠平台、本平台”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您一旦访问、浏览或使用<span class="app_name"></span>（以下简称我们、米花棠、米花棠平台、本平台），表明您已阅读、理解并同意接受以下条款的约束，并遵守所适用的法律和法规。请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私权政策》。
        </div>
        <div>
            <h3>一、我们如何收集您的个人信息</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本隐私政策中包括以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息，包括：姓名、性别、昵称、年龄、职业信息、出生年月日、身份证或其他身份证明信息（包括：中华人民共和国居民身份证、港澳居民来往内地通行证、台湾居民来往大陆通行证、护照、外国人永久居留身份证可证明您身份的信息，该等信息以下统称“证件信息”，所涉各身份证件以下统称“证件”）、证件照片、（真实）头像照片、面部识别特征、住所地、常用地址、紧急联系人、手机号码、用户密码、电子邮箱地址及其他相关附加信息、支付账户信息、银行账户信息、验证信息、验证信息凭证、手机通讯录、手机应用列表、设备信息、日志信息、IP地址、信用信息、行程信息、位置信息、交易状态、以及商城信息。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，本隐私政策中涉及的个人敏感信息包括：手机号码、证件信息、面部识别特征、位置信息、行程信息，通话记录、录音录像、支付信息。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;匿名化处理后的信息以及其他无法识别特定自然人身份或者反映特定自然人活动情况的信息不属于个人信息。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;其他无法识别特定自然人身份或者反映特定自然人活动情况的信息不属于个人信息。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们仅会出于以下目的，收集和使用您的个人信息：<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（一）您须授权我们收集和使用您的个人信息的情形<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的产品和服务包括一些基本业务功能和扩展业务功能。基本业务功能包含了实现出行信息交换及出行订单在线交易所必须的功能及保障服务正常运行和交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些基本业务功能必须收集的信息包括：<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1 用户注册、认证所必需的信息<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您使用米花棠平台服务前，需要在米花棠平台创建账号并完善个人资料。通过用户主动提供的方式，米花棠平台会收集您的以下信息：<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（1）当您注册成为米花棠平台用户或登录米花棠平台账号时， 您需要向米花棠平台提供您本人的手机号码，并准确输入米花棠平台以短信形式发送至您手机的验证码，且允许米花棠平台获取您的手机IMEI信息，开启读写存储卡权限。米花棠平台以此识别您在米花棠平台的网络身份和标识您的设备，并为您提供服务。如果您拒绝提供手机号码和验证码，或不允许米花棠平台获取您的手机IMEI信息，并开启读写存储卡权限，米花棠平台可能无法提供后续的相关拓展服务。
            基于我们与通信运营商的合作，当您使用 “一键登录”功能时，经过您的明示同意，运营商会将您的手机号发送给我们，便于我们为您提供快捷的登录服务。如果拒绝提供手机号将无法使用“一键登录”方式注册登录，但不影响您通过其他方式注册登录，也不影响其他功能的正常使用。请您注意，如果您的手机同时运行两张通信卡，我们将获取您使用移动数据流量卡的手机号码。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（2）您可以选择是否提供常用地址 （包括家的地址及公司地址），设置常用地址后可以提高服务效率。如果您拒绝设置常用地址，则需要您手动输入地址信息。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（3）当您在平台进行实名认证时， 您需要根据米花棠平台平台提示的要求，向米花棠平台提供您的相关信息（包括姓名、证件号、本人真实头像照片、证件照片以及手持证件头像照片、面部识别特征信息）。米花棠平台收集上述信息，是基于法律法规要求、保护用户人身财产安全、保障交易真实、依照平台规则处理用户纠纷之需要。为进行实名认证，米花棠平台将向米花棠平台合作的第三方提交您的前述信息，并从合法保存有您个人信息的第三方处获得、保存有关您身份信息的验证结果，如果您拒绝提供上述信息或拒绝米花棠平台作前述信息分享、保存，您将无法作为米花棠平台实名认证用户使用米花棠平台服务。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（4）如果您选择使用第三方账号（如微信等，具体以页面实时呈现为准）登录我们的服务，我们将从该第三方获取您授权的信息（第三方用户昵称、第三方用户头像及您授权的其他信息）。使用微信关联登录微信可能会使用到您的剪切板读写与写入权限，这些信息仅供实现登录相关的目的所使用，不会收集您的隐私信息。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.2 出行服务在线交易所必需的信息<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您使用米花棠平台平台服务时，米花棠平台将在您发送订单请求至订单完成过程中，收集您的以下信息：<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（1）位置信息：以便您不需要手动输入地理坐标即可获得相关的服务。在您首次使用米花棠平台平台前， 米花棠平台会提示您选择是否允许米花棠平台平台获取以及何种情况下可以获取您的位置信息。在您使用米花棠平台平台的过程中，您可以随时通过您的设备设置功能，选择是否允许米花棠平台平台获取以及何种情况下可以获取您的位置信息。获得您的允许后，米花棠平台会通过IP地址、GPS以及能够提供相关信息的其他传感器（如会为米花棠平台提供附近设备、Wi-Fi接入点、蓝牙和基站信息）来获取您的位置信息。为了便于用户出行，车主接乘，乘客上车，安全护航，我们会持续收集设备信息和设备的位置信息，来区分用户身份和当前位置，提高出行质量和安全。如果您拒绝米花棠平台平台获取位置信息，您将需要手动定位您的位置。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（2）沟通信息：您通过米花棠平台发送的语音、图片或文字信息内容及通话记录，米花棠平台将通过信息记录的方式留存上述信息，以便用户发生纠纷或安全事故时，核实事实并处理投诉。如果您拒绝米花棠平台获取您的沟通信息，可能导致您无法使用米花棠平台的信息功能。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（3）订单信息：您通过米花棠平台在线上达成出行订单，米花棠平台将收集您填写并提供的订单信息，包括地址、费用金额并自动收集运单的交易状态，我们在您使用我们的服务过程中收集上述信息并向您展示，是基于米花棠平台为您提供在线交易、客诉服务以及依照平台规则处理用户纠纷所必须；我们收集上述信息以核实您是否按约支付费用，并按照平台规则处理用户纠纷。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（4）支付信息：您在米花棠平台平台进行交易支付时， 如果您使用第三方支付功能， 米花棠平台除获取您的支付工具及订单支付状态外不会获取其他个人信息。米花棠平台将记录您的平台账户余额、支付记录、提现记录以及第三方支付账号。米花棠平台收集上述信息以确认用户是否按约支付费用、订单是否收费异常，维护平台交易秩序，并按照平台规则处理用户纠纷。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（5）评价信息：在米花棠平台在线交易完成后，您可针对此次交易进行评价，米花棠平台将收集并展示您主动发布的评价以及发布的其他内容。请您谨慎填写避免造成个人敏感信息泄露，您未主动评价时系统将默认好评。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.3 客户服务所必需的信息<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您与米花棠平台客服联系时，为验证您的身份，帮助您解决问题，米花棠平台会以通话录音、人工录入的方式记录您的通话信息、通话内容以及您提供的姓名、联系方式等为解决问题所需的必要信息，以便米花棠平台处理纠纷、解决问题或向您反馈结果。如果您拒绝提供此类信息，会导致您无法使用米花棠平台的客服服务。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.4 维护服务正常运行、保障交易安全所必需的信息<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您使用米花棠平台（平台运行在前台、后台或静默状态）时，为保障您正常使用我们的服务，维护我们服务的正常运行，改进及优化我们的服务体验以及保障您的账号安全，我们会接受并记录您的设备相关信息（包括型号、品牌、操作系统、设备设置、设备环境、唯一设备标识符、硬件设备序列号、设备硬件序列号、登陆IP地址、MAC地址、IMSI信息、IMEI信息、MEID信息、SIM卡归属地、通讯端口号、米花棠平台软件版本号，接入网络的方式、类型和状态、网络质量数据和网络环境、移动应用列表）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、经纬度、城市码、蓝牙和基站）以及操作日志、服务日志信息（包括您在捜索、查看的信息、交易信息、浏览器类型和标识、SIM卡运营商、使用语音、访问日期和时间、服务故障信息、网址），这类信息是为提供服务必须收集的基础信息。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.5 行程录音及录像信息的采集。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为提升米花棠平台服务产品安全能力、保护司乘安全及更好地处理司乘纠纷，您同意米花棠平台在为您提供网约预约出租车服务时采集您的行程内的录音录像信息。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（二）您可选择是否授权我们收集和使用您的个人信息的情况<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;除上述基本业务功能我们会收集和使用您的个人信息以外，我们的产品和服务还包括一些扩展业务功能，我们可能会在您使用扩展业务功能时收集和使用您的个人信息，如果您不提供这些个人信息，您依然可以实现出行服务的在线交易，但您可能将无法使用这些扩展业务功能：<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.1 基于设备权限开启的扩展业务功能<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（1）基于位置信息的扩展功能：我们会收集您的位置信息（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点，自动为您展示您所在区域的附近车源。您可以授权提供您的位置信息，以便您基于所在地点位置接受派单。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（2）基于语音技术的扩展功能：您可以直接使用麦克风来进行语音定位或与司机沟通、与我们的在线客服进行咨询和互动。在这些功能中我们会收集您的录音内容以识别您的需求。您可以选择使用麦克风设备来进行语音输入（例如：语音消息、语音打车），在使用过程中相关服务提供方需要收集您的语音内容并进行必要的处理。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（3）基于摄像头（相机）的扩展功能：您可以授权米花棠平台访问您的摄像头（相机）进行视频拍摄、拍照以及扫码，我们会识别您的视频、照片以及扫码信息来完成用户认证、保险理赔、客服服务、活体检测以及扫码支付功能。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（4）基于相册（图片库）的扩展功能：您可以授权米花棠平台访问您的相册并在米花棠平台上传您的照片来实现用户认证、客服服务以及评价等功能，我们会使用您所上传的照片来识别您的认证信息、理赔资料信息、客户服务信息或使用包含您所上传图片的评价等。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（5）基于剪切板的扩展功能：在您分享或接收被分享的信息、参加活动等情形下，我们需要访问您的剪切板，读取其中包含的口令、分享码、链接，以实现跳转、分享、活动联动等功能或服务。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（6）基于访问应用列表权限的扩展功能：在您通过米花棠平台点击使用第三方导航地图、第三方支付软件或向第三方软件（例如微信）分享信息时，我们会访问您的应用列表来判断您是否安装了前述特定软件，我们并不会收集您的全部应用列表信息，仅判断您是否安装了前述特定软件并获得判断结果用于支持您向第三方的跳转。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（7）基于通讯录的扩展功能：您可以授权我们获取您的通讯录权限、读取您的通讯录信息以帮助您快速录入紧急联系人或乘车人的姓名与手机号码，当然，如果您拒绝也可以通过手动输入的方式完成前述信息填写。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（8）基于关联启动的扩展说明：如果您使用的是华为手机或其他我们在第三方应用商店投放广告的手机品牌，当您首次启动米花棠平台APP时，我们将自动关联启动您手机应用商店APP，以实现应用商店广告归因之目的。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（9）基于开机自启动的扩展说明：我们将在您开机时通过后台自启动并查收您在米花棠平台APP中的IM离线消息（即您与司机的对话框消息）。我们自启动仅用于确认您是否有最新的IM消息，以免您错过最新的沟通讯息，并不会在前台打开您的米花棠平台APP或运行其他功能。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;上述扩展功能可能需要您在您的设备中向我们开启您的相应权限，以实现这些功能所涉及的信息的收集和使用。您可以决定将这些权限随时开启或关闭（我们可指引您在您的设备系统中完成设置，上述部分权限/功能可能因您使用的手机系统差异或系统权限本身设置导致无法自行开关）。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.2 开具发票<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您使用打车服务需要取得发票时，服务的提供方需要您提供发票抬头、纳税人识别号、税务登记地址、联系电话、开户行、银行卡号、电子邮箱、订单信息、收取发票的收件人姓名、联系电话、收件地址。米花棠平台收集前述信息后将向开具发票的第三方共享前述信息，米花棠平台收集这些信息及共享是为向您开具、邮寄发票。如您拒绝提供前述信息，将导致您无法通过米花棠平台开具、收取发票。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.3 开展数据分析、不断改善产品和服务<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为开展数据分析和更好改善米花棠平台服务， 我们可能将业务时收集的个人信息用于统计分析和改进运营，例如通过您所在的位置、运单信息、浏览和搜索信息、充值等消费记录、路线偏好等进行统计分析，从而改进我们的产品、服务或营销计划；又如为改进我们系统而进行的技术改造、网络维护、故障排除、内部政策与流程制定、生成内部报告等。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.4 对第三方责任的声明<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;请您注意，您可能通过米花棠平台跳转功能访问第三方网站经营者、通过米花棠平台接入第三方服务（如有），以上第三方可能有自己的隐私权保护政策。当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会获取、使用您的个人信息，该等获取、使用不受米花棠平台的控制及本政策的约束。米花棠平台会尽商业上的合理努力去要求这些第三方对您的个人信息采取保护措施，但米花棠平台无法保证这些第三方一定会按照米花棠平台的要求采取保护措施，请您与这些第三方直接联系以获得关于他们的隐私权政策的详细情况。如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.5 设备权限调用<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;米花棠平台可能会基于某些需要的具体应用场景调用您的一些对应的必要设备权限，并在调用前向您弹窗询问。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您可以在设备的设置中选择关闭部分或者全部权限，这可能导致对于的业务功能无法实现或者无法达成预期目的。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.6 其他服务收集、使用、提供信息<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如您选择使用我们提供的其他服务，基于该服务我们需要收集您的信息的，我们会另行向您说明信息收集的范围与目的，并征得您的同意。我们会按照本政策以及相应的用户协议约定使用、存储、对外提供及保护您的信息。如您选择不提供前述信息，您可能无法使用某项或某部分服务，但不影响您使用我们提供的其他服务。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.7 其他用途<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们将您的个人信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的您的个人信息用于其他目的时，会单独征求您的授权同意。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;请注意：无法与任何特定个人直接建立联系的数据，不属于个人信息。如果我们将无法与任何特定个人建立联系的数据与其他信息结合用于识别自然人个人身份，或者将其与个人信息结合使用，则在结合使用期间，此类信息将被视为个人信息。<br>
        </div>
        <div>
            <h3>二、设备权限调用</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（一）米花棠平台可能会调用您的一些设备权限，以下是调用权限对应的业务功能、我们调用权限的目的，以及调用权限前向您询问的情况。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（二）您可以在设备的设置功能中选择关闭不分或全部权限，从而拒绝米花棠平台收集相应的个人信息。使用Android系统的不同设备中，权限显示方式及关闭方式可能有所不同，具体请联系设备及系统开发方。<br>
            
            <span id="q33"></span>
            <span id="q34"></span>
        </div>
       
        <div>
            <h3>三、我们如何共享、转让、公开披露您的个人信息</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（一）共享<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3、与我们的关联公司共享：您的个人信息可能会与我们关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4、与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，我们聘请来提供第三方数据统计和分析服务的公司可能需要采集和访问个人数据以进行数据统计和分析。在这种情况下，这些公司 必须遵守我们的数据隐私和安全要求。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（二）转让<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（三）公开披露<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们仅会在以下情况下，公开披露您的个人信息：<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1、获得您明确同意后；<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。<br>                       
        </div>
        <div>
            <h3>四、我们如何保护您的个人信息</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如信用卡信息）时受 SSL 加密保护；我们同时对我们网站提供 https 安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。<br>            
        </div>
        <div>
            <h3>五、您的权利</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（一）访问您的个人信息<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;账户信息——如果您希望访问或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息或关闭您的账户等。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如果您无法通过上述访问这些个人信息，您可以随时发送电子邮件至kefu@disfc.com,我们将在14天内回复您的访问请求。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（二）更正您的个人信息<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（三）删除您的个人信息<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在以下情形中，您可以向我们提出删除个人信息的请求：<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1、如果我们处理个人信息的行为违反法律法规；<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、如果我们收集、使用您的个人信息，却未征得您的同意；<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3、如果我们处理个人信息的行为违反了与您的约定；<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4、如果您不再使用我们的产品或服务，或您注销了账号；<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5、如果我们不再为您提供产品或服务。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（四）改变您授权同意的范围<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您可通过手机自身的权限设置，对允许本app访问的手机权限进行设置和修改。您撤回或者改变您的授权，不会影响此前基于您的授权而进行的个人信息手机、使用行为。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（五）个人信息主体注销账户<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您随时可注销此前注册的账户，您可以通过以下方式自行操作：个人中心--设置--注销账号，发起注销账号申请，账号将在发起申请后7天内自动注销。账户注销之后，您的所有信息都将随之删除，请慎重操作。<br>      
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（六）约束信息系统自动决策<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（七）响应您的上述请求<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在以下情形中，按照法律法规要求，我们将无法响应您的请求：<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1、与国家安全、国防安全有关的；<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、与公共安全、公共卫生、重大公共利益有关的；<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3、与犯罪侦查、起诉和审判等有关的；<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4、有充分证据表明您存在主观恶意或滥用权利的；<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。<br>            
        </div>
        <div>
            <h3>六、我们如何处理儿童的个人信息</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;尽管当地法律和习俗对儿童的定义不同，但我们将不满 14 周岁的任何人均视为儿童。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。<br>            
        </div>
        <div>
            <h3>七、您的个人信息如何在全球范围转移</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。<br>            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。<br>            
        </div>
        
        <div>
            <h3>八、米花棠相关App接入第三方平台:</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为保障米花棠相关App相关功能的实现与应用安全稳定的运行，我们可能会接入由第三方提供的软件开发包（SDK）实现相关目的。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们会对合作方获取信息的软件工具开发包(SDK)进行严格的安全监测，以保护数据安全。<br>         
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;第三方SDK可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，请以其公示的官方说明为准。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们与第三方 SDK 类服务商共享个人数据： 我们相关服务或产品中可能会包含第三方SDK或其他类似的应用程序，如您在我们平台上使用这类由第三方提供的服务或产品时，您同意将由其直接收集和处理您的信息（如以嵌入代码、插件等形式）。前述服务商收集和处理信息等行为遵守其自身的隐私条款，而不适用于本隐私政策。但我们也会努力审查该第三方的业务准入资质并努力要求该服务商的合法合规性与安全性。为了最大程度保障您的信息安全，我们强烈建议您在使用任何第三方SDK类服务前先行查看其隐私条款。为保障您的合法权益，如您发现这等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系。<br>
        </div>

        <div>
            <h3>九、第三方链接及服务</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;米花棠平台可能保留有第三方网站或网址的链接及第三方服务,是否访问这些链接或接受相应服务将由用户自己作出决定,米花棠平台并不就这些链接上所提供的任何信息、数据、观点、图片、陈述或建议的准确性、完整性、充分性和可靠性提供承诺或保证。米花棠平台没有审查过任何第三方网站,对这些网站及其内容不进行控制,也不负任何责任。如果您决定访问任何与本站链接的第三方网站,其可能带来的结果和风险全部由您自己承担。<br>
        </div>
        <div>
            <h3>十、适用法律和管辖权</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;通过访问米花棠平台使用米花棠平台提供的或其合作服务提供商提供的服务,即表示您同意该访问或服务受中华人民共和国法律的约束,且您同意受中华人民共和国法院的管辖。访问或接受服务过程中发生的争议应当协商解决,协商不成的由实际服务提供者或实际网络信息服务提供者住所地有管辖权的法院诉讼解决。即，由米花棠平台所地有管辖权的法院管辖。<br>            
        </div>
        <div>
            <h3>十一、本政策如何更新</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本隐私权政策可能适时变更。
            <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本隐私权政策的任何更新将以标注更新时间的方式公布在我们网站上，除法律法规或监管规定另有强制性规定外。
            <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;对于重大变更，我们还会提供更为显著的通知（包括我们会通过平台推送公示的方式进行通知甚至向您提供弹窗提示）
            <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本政策所指的重大变更包括但不限于：
            <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等等；
            <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；
            <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3、个人信息共享、转让或公开披露的主要对象发生变化；
            <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
            <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；
            <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6、个人信息安全影响评估报告表明存在高风险。您可以在本平台客户端“我的-设置-法律条款与平台规则-隐私权政策中查看本政策的最新版本。
        </div>
        <div>
            <h3>十二、如何联系我们</h3>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1、我们设立了个人信息反馈专项处理机制，如您对个人信息有任何疑问，可通过人工客服（app首页的个人中心—>帮助中心—>人工客服）反馈，我们将在14天内核实并处理。<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、如果您对本隐私政策有任何疑问、意见建议，均可通过人工客服（app首页的个人中心—>帮助中心—>人工客服）与我们联系<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4、如果您对我们的回复不满意，且认为我们个人信息处理行为损害了您的合法权益，您可以向网信、工商监管部门进行投诉或举报。<br>
            
            <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司名称：成都米花棠科技有限公司<br>
        

            
        </div>
        <div class="p"></div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'TaxiH5webAgreement',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.bigbox{
  padding-bottom: 60px;
            font-size: 12px;
            line-height: 20px;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100vh;
}
.box{
  flex: 1;
  overflow-y: scroll;
  margin-left: 10px;
   margin-right: 10px;
     box-sizing: border-box;
}
  .title {
            width: 100%;
            text-align: center;
        }

        .p {
            margin: 0.8rem 0 0.5rem 0;
        }
        .time{
            position: absolute;
            left: 60%;
        }
        table{
            border-collapse: collapse;
            margin: 0 auto;
            text-align: center;
        }
        table td, table th{
            border: 1px solid #cad9ea;
            color: #666;
            height: 30px;
        }
        table thead th{
            background-color: #CCC;
            /* width: 50px; */
        }
</style>